<template>
  <Component :is="data.style === 'side_image' ? BaseSection : 'div'">
    <Component
      :is="getPictureAccordionType"
      :slides="slidesTransformer"
    />
  </Component>
</template>

<script setup lang="ts">
import { defineAsyncComponent, computed, type Component } from 'vue';
import { type PictureAccordionVariants, type SliderItem } from '~/types';
import BaseSection from '../BaseSection.vue';

const props = defineProps({
  data: {
    required: true,
    type: Object,
  },
});

type SliderVariant = {[key in PictureAccordionVariants]: Component};

const heroStyle = computed<PictureAccordionVariants>(() => props.data?.style);
const getPictureAccordionType = computed(() => {
  const style: PictureAccordionVariants = heroStyle.value || 'side_image';
  const mapping: SliderVariant = {
    side_image: defineAsyncComponent(() => import('./../sliders/AccordionListSlider.vue')),
    background_image: defineAsyncComponent(() => import('./../sliders/AccordionListBackgroundSlider.vue')),
  };

  return mapping[style];
});

const slidesTransformer = computed<SliderItem[]>(() => {
  const serverSlides = props.data.questions;

  return serverSlides.map((slide: Record<string, any>) => {
    const image = slide?.picture?.original || {};
    const imageSrc = image?.src;
    const transformedSlide: SliderItem = {
      label: slide.title,
      body: slide.content,
      imageSrc,
      imageAlt: image?.alt || '',
    };

    return transformedSlide;
  });
});
</script>
